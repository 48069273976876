import { Table } from "antd";
import React from "react";

function TableDeclarationsUserList(props) {
  const {
    category,
    laborUnionOption,
    laborUnion,
    buildingConventionOutside77,
    workForce,
    userIsAdmin,
    isVerified,
    data,
    filters,
    totalItems,
    handleTableChange,
    isLoading,
  } = props;

  const columns = [
    {
      title: "Code Adhérent",
      dataIndex: "membershipCode",
      key: "membershipCode",
      align: "center",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "Raison Sociale",
      dataIndex: "companyName",
      key: "companyName",
      align: "center",
    },
    {
      title: "Représentant",
      dataIndex: "representativeName",
      key: "representativeName",
      align: "center",
    },
    {
      title: "Catégorie",
      dataIndex: "membershipCategory",
      key: "membershipCategory",
      align: "center",
      filters: [
        { text: "4 : Etam Cadres & Ouvriers", value: "4" },
        { text: "5 : Adhérent Volontaire", value: "5" },
        { text: "6 : Adhérent CAPEB / - de 10", value: "6" },
      ],
      filteredValue: category,
    },
    {
      title: "Adhérent",
      dataIndex: "laborUnion",
      key: "laborUnion",
      align: "center",
      filters: laborUnionOption,
      filteredValue: laborUnion,
    },
    {
      title: "Conv. Btm. hors 77",
      dataIndex: "buildingConventionOutside77",
      key: "buildingConventionOutside77",
      align: "center",
      filters: [
        { text: "oui", value: "true" },
        { text: "non", value: "false" },
      ],
      filteredValue: buildingConventionOutside77,
    },
    {
      title: "Effectifs",
      dataIndex: "workForce",
      key: "workForce",
      align: "center",
      filters: [
        { text: "Jusqu'à 10", value: "upTo10" },
        { text: "Plus de 10", value: "moreThan10" },
      ],
      filteredValue: workForce,
    },
    {
      title: "Admin",
      dataIndex: "isAdmin",
      key: "isAdmin",
      align: "center",
      filters: [
        { text: "oui", value: "true" },
        { text: "non", value: "false" },
      ],
      filteredValue: userIsAdmin,
      defaultFilteredValue: ["false"],
    },
    {
      title: "Vérifié",
      dataIndex: "isVerified",
      key: "isVerified",
      align: "center",
      filters: [
        { text: "oui", value: "true" },
        { text: "non", value: "false" },
      ],
      filteredValue: isVerified,
      defaultFilteredValue: ["true"],
    },
    {
      title: "Modifier",
      key: "edit",
      dataIndex: "edit",
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      style={{ flexGrow: 1 }}
      scroll={{ x: "100%" }}
      rowKey={(row) => row.id}
      pagination={{
        current: filters.page,
        pageSize: 50,
        total: totalItems,
      }}
      onChange={handleTableChange}
      loading={isLoading}
    />
  );
}

export default TableDeclarationsUserList;
