import { Table } from "antd";
import React from "react";

function TableDeclarations(props) {
  const { category, handleTableChange, data, filters, totalItems, isLoading } =
    props;

  const columns = [
    {
      title: "Code Adhérent",
      dataIndex: "membershipCode",
      key: "membershipCode",
      align: "center",
    },
    {
      title: "Raison Sociale",
      dataIndex: "companyName",
      key: "companyName",
      align: "center",
    },
    {
      title: "Catégorie Adhérent",
      dataIndex: "membershipCategory",
      key: "membershipCategory",
      align: "center",
      filters: [
        { text: "4 : Etam Cadres & Ouvriers", value: "4" },
        { text: "5 : Adhérent Volontaire", value: "5" },
        { text: "6 : Adhérent CAPEB / - de 10", value: "6" },
      ],
      filteredValue: category,
      // onChange: (e) => {
      //   handleTableChange();
      // },
    },
    {
      title: "Date saisie",
      dataIndex: "formatedInputedDate",
      key: "formatedInputedDate",
      align: "center",
    },
    {
      title: "Date de validation",
      dataIndex: "formatedValidatedDate",
      key: "formatedValidatedDate",
      align: "center",
    },
    {
      title: "Montant de la cotisation",
      dataIndex: "feeAmount",
      key: "feeAmount",
      align: "right",
    },
    {
      title: "Effectifs déclarés",
      dataIndex: "workForce",
      key: "workForce",
      align: "right",
    },
    {
      title: "Méthode paiement",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      align: "center",
    },
    {
      title: "Réglé",
      dataIndex: "payed",
      key: "payes",
      align: "center",
    },
    {
      title: "Actions",
      key: "edit",
      dataIndex: "edit",
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      style={{ flexGrow: 1 }}
      scroll={{ x: "100%" }}
      rowKey={(row) => row.id}
      pagination={{
        current: filters.page,
        pageSize: 50,
        total: totalItems,
      }}
      onChange={handleTableChange}
      loading={isLoading}
    />
  );
}

export default TableDeclarations;
