import axios from "axios";
import React, { useState, useContext, useLayoutEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BACKEND_URL } from "../../config/config";
import { NotificationContext } from "../context/NotificationWrapper";
import { Result } from "antd";
import { Spin } from "antd";

function RegisterConfirmation(props) {
  const backendUrl = BACKEND_URL;
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { openNotification } = useContext(NotificationContext);
  const [isLoading, setIsLoading] = useState(true);
  let location = useLocation();

  useLayoutEffect(() => {
    sendIsVerified();
  }, []);

  const sendIsVerified = () => {
    setIsLoading(true);
    setError(false);
    setSuccess(false);
    const url =
      props?.isChangeEmail === true ? "verify/change/email" : "verify/email";
    let headers = {};
    if (props?.isChangeEmail === true) {
      headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    } else {
      headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
    }
    axios
      .get(`${backendUrl}/api/register/${url}${location.search}`, {
        withCredentials: true,
        headers: headers,
      })
      .then((response) => {
        setIsLoading(true);
        setSuccess(true);
        setError(false);
        if (props?.isChangeEmail === true) {
          openNotification("Succès", "Adresse vérifiée", "success");
          setTimeout(() => {
            navigate("/infoIdentifier");
          }, 1500);
          setIsLoading(false);
        } else {
          openNotification("Succès", "Inscription terminée", "success");
          setTimeout(() => {
            navigate("/");
            setIsLoading(false);
          }, 1500);
        }
      })
      .catch((error) => {
        console.error("error", error);
        openNotification(
          "Erreur",
          "Une erreur est survenue, veuillez réessayer",
          "error"
        );
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="form-auth" style={{ width: "100%" }}>
        <div
          className="form-container"
          style={{ alignItems: "center", width: "80%", padding: "40px 20px" }}
        >
          <h2>Vérification de l'adresse e-mail</h2>

          <Spin spinning={isLoading} fullscreen />
          {error && (
            <Result
              status="error"
              title="Une erreur est survenue"
              subTitle="La validation de l'e-mail n'a pas fonctionné."
            />
          )}
          {success && <Result status="success" title="E-mail validé" />}
        </div>
      </div>
    </>
  );
}

export default RegisterConfirmation;
