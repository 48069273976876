import "./App.scss";
import { Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import MenuNavigation from "./components/left-menuNavigation/MenuNavigation";
import Login from "./components/auth/Login";
import Signup from "./components/auth/Signup";
import InfoIdentifier from "./components/right-contentNavigation/userDetail-CRUD/InfoPage";
import Connexion from "./components/auth/Connexion";
import InfoDetailUpdate from "./components/right-contentNavigation/userDetail-CRUD/InfoDetailUpdate";
import IsPrivate from "./components/context/IsPrivate";
import DeclarationListPage from "./components/right-contentNavigation/declarationList/DeclarationListPage";
import ImportPage from "./components/right-contentNavigation/import/ImportPage";
import UsersPage from "./components/right-contentNavigation/userList/UserListPage";
import { useMediaQuery } from "react-responsive";
import ResetPassword from "./components/auth/resetPassword/ResetPassword";
import ResetPasswordForm from "./components/auth/resetPassword/ResetPasswordForm";
import NoMatch from "./components/noMatch/NoMatch";
import EmptyUser from "./components/noMatch/EmptyUser";
import RegisterConfirmation from "./components/auth/RegisterConfirmation";
import ChangePasswordPage from "./components/right-contentNavigation/userDetail-CRUD/ChangePassword";
import ChangeEmailPage from "./components/right-contentNavigation/userDetail-CRUD/ChangeEmail";
import DeclarationSummaryPage from "./components/right-contentNavigation/declarationSummary/DeclarationSummaryPage";
import DeclarationTransferBankPage from "./components/right-contentNavigation/payment/paymentByTransferBank/DeclarationTransferBankPage";
import DeclarationCBPage from "./components/right-contentNavigation/payment/paymentByCB/DeclarationCBPage";
import SuccessResultPayment from "./components/right-contentNavigation/payment/resultPayment/SuccessResultPayment";
import FailureResultPayment from "./components/right-contentNavigation/payment/resultPayment/FailureResultPayment";
import DeclarationSignature from "./components/right-contentNavigation/declarationSignature/DeclarationSignature";
import DeclarationCertificatePayment from "./components/right-contentNavigation/declarationCertificatePayment/DeclarationCertificatePayment";
import PaymentStatusChangePage from "./components/right-contentNavigation/paymentStatusChange/paymentStatusChangePage";
import PendingResultPayment from "./components/right-contentNavigation/payment/resultPayment/PendingResultPayment";
import TermsServicePage from "./components/right-contentNavigation/termsService/TermsServicePage";
import LegalMentionsPage from "./components/right-contentNavigation/termsService/LegalMentionsPage";
import CreateDeclaration from "./components/right-contentNavigation/declarationDetail-CRUD/CreateDeclaration";
import FooterPage from "./components/footer/FooterPage";
import AdminDashboardPage from "./components/right-contentNavigation/admin/AdminDashboardPage";

function App() {
  const isSmallScreen = useMediaQuery({ maxWidth: 990 });

  return (
    <>
      <div className="page-wrap">
        <Header />
        <Routes>
          <Route path="/" element={<Connexion />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-form/:slug" element={<ResetPasswordForm />} />
          <Route path="/confirm-email" element={<RegisterConfirmation />} />
          <Route
            path="/confirm-change-email"
            element={<RegisterConfirmation isChangeEmail={true} />}
          />
          <Route
            path="/tableDeclaration"
            element={
              <div className="content-container">
                <IsPrivate>
                  {!isSmallScreen && <MenuNavigation />}
                  <DeclarationListPage />
                </IsPrivate>
              </div>
            }
          />

          <Route
            path="/infoIdentifier"
            element={
              <div className="content-container">
                <IsPrivate>
                  {!isSmallScreen && <MenuNavigation />}
                  <InfoIdentifier />
                </IsPrivate>
              </div>
            }
          />

          <Route
            path="/infoIdentifier/infoDetail/update"
            element={
              <div className="content-container">
                <IsPrivate>
                  {!isSmallScreen && <MenuNavigation />}
                  <InfoDetailUpdate />
                </IsPrivate>
              </div>
            }
          />

          <Route
            path="/declaration"
            element={
              <div className="content-container">
                <IsPrivate>
                  {!isSmallScreen && <MenuNavigation />}
                  <CreateDeclaration />
                </IsPrivate>
              </div>
            }
          />

          <Route
            path="/users"
            element={
              <div className="content-container">
                <IsPrivate>
                  {!isSmallScreen && <MenuNavigation />}
                  <UsersPage />
                </IsPrivate>
              </div>
            }
          />

          <Route
            path="/import"
            element={
              <div className="content-container">
                <IsPrivate>
                  {!isSmallScreen && <MenuNavigation />}
                  <ImportPage />
                </IsPrivate>
              </div>
            }
          />

          <Route
            path="/change-password"
            element={
              <div className="content-container">
                <IsPrivate>
                  {!isSmallScreen && <MenuNavigation />}
                  <ChangePasswordPage />
                </IsPrivate>
              </div>
            }
          />

          <Route
            path="/change-email"
            element={
              <div className="content-container">
                <IsPrivate>
                  {!isSmallScreen && <MenuNavigation />}
                  <ChangeEmailPage />
                </IsPrivate>
              </div>
            }
          />

          <Route
            path="/declaration-summary"
            element={
              <div className="content-container">
                <IsPrivate>
                  {!isSmallScreen && <MenuNavigation />}
                  <DeclarationSummaryPage />
                </IsPrivate>
              </div>
            }
          />
          <Route
            path="/declaration-summary/success"
            element={
              <div className="content-container">
                {!isSmallScreen && <MenuNavigation />}
                <SuccessResultPayment />
              </div>
            }
          />
          <Route
            path="/declaration-summary/failure"
            element={
              <div className="content-container">
                {!isSmallScreen && <MenuNavigation />}
                <FailureResultPayment />
              </div>
            }
          />
          <Route
            path="/declaration-summary/pending"
            element={
              <div className="content-container">
                {!isSmallScreen && <MenuNavigation />}
                <PendingResultPayment />
              </div>
            }
          />
          <Route
            path="/declaration-payment"
            element={
              <div className="content-container">
                <IsPrivate>
                  {!isSmallScreen && <MenuNavigation />}
                  <DeclarationTransferBankPage />
                </IsPrivate>
              </div>
            }
          />
          <Route
            path="/declaration-cb"
            element={
              <div className="content-container">
                <IsPrivate>
                  {!isSmallScreen && <MenuNavigation />}
                  <DeclarationCBPage />
                </IsPrivate>
              </div>
            }
          />
          <Route
            path="/declaration-signature"
            element={
              <div className="content-container">
                <IsPrivate>
                  {!isSmallScreen && <MenuNavigation />}
                  <DeclarationSignature />
                </IsPrivate>
              </div>
            }
          />
          <Route
            path="/declaration-certificate-payment"
            element={
              <div className="content-container">
                <IsPrivate>
                  {!isSmallScreen && <MenuNavigation />}
                  <DeclarationCertificatePayment />
                </IsPrivate>
              </div>
            }
          />
          <Route
            path="/payment-status-change"
            element={
              <div className="content-container">
                <IsPrivate>
                  {!isSmallScreen && <MenuNavigation />}
                  <PaymentStatusChangePage />
                </IsPrivate>
              </div>
            }
          />
          <Route
            path="/admin"
            element={
              <div className="content-container">
                <IsPrivate>
                  {!isSmallScreen && <MenuNavigation />}
                  <AdminDashboardPage />
                </IsPrivate>
              </div>
            }
          />
          <Route
            path="/charte-adherent"
            element={
              <div className="content-container-terms">
                <TermsServicePage />
              </div>
            }
          />
          <Route
            path="/mentions-legales"
            element={
              <div className="content-container-terms">
                <LegalMentionsPage />
              </div>
            }
          />
          <Route path="/empty" element={<EmptyUser />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </div>
      <FooterPage />
    </>
  );
}

export default App;
