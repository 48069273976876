import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthProviderWrapper";
import axios from "axios";
import { BACKEND_URL } from "../../../config/config";
import { Button, ButtonLink } from "../../misc/button/Button";
import { Select, Checkbox, Spin } from "antd";
import InfoRow from "./InfoRow";
import { NotificationContext } from "../../context/NotificationWrapper";
import SpinLoading from "../../misc/loading/SpinLoading";
import { renderCheckboxOrHidden } from "../../helper-function/helperFunction";
import DetacheGroup from "../multiAccount/DetacheGroup";

const InfoDetailUpdate = () => {
  const { activeCompany, user, isAdmin, changeActiveCompany } =
    useContext(AuthContext);
  const [userUpdate, setUserUpdate] = useState({
    address1: null,
    address2: null,
    address3: null,
    address4: null,
    city: null,
    companyName: null,
    companyStatus: null,
    email: null,
    isAdmin: null,
    isVerified: null,
    membershipCategory: null,
    membershipCode: null,
    naf: null,
    representativeFirstname: null,
    representativeFunction: null,
    representativeLastname: null,
    representativePhone: null,
    siren: null,
    workForce: null,
    zipcode: null,
    laborUnion: null,
    buildingConventionOutside77: null,
    agreeOptionalWorker: false,
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { openNotification } = useContext(NotificationContext);
  const [conditionCheckbox, setConditionCheckbox] = useState(false);
  const userRef = useRef(user);

  useEffect(() => {
    const condition = renderCheckboxOrHidden(userUpdate, null);
    setConditionCheckbox(condition);
  }, [userUpdate]);

  useEffect(() => {
    if (activeCompany) {
      setUserUpdate({
        companyStatus: activeCompany.companyStatus,
        siren: activeCompany.siren,
        naf: activeCompany.naf,
        membershipCategory: activeCompany.membershipCategory,
        membershipCode: activeCompany.membershipCode,
        companyName: activeCompany.companyName,
        address1: activeCompany.address1,
        address2: activeCompany.address2,
        address3: activeCompany.address3,
        address4: activeCompany.address4,
        zipcode: activeCompany.zipcode,
        city: activeCompany.city,
        representativeLastname: activeCompany.representativeLastname,
        representativeFirstname: activeCompany.representativeFirstname,
        representativeFunction: activeCompany.representativeFunction,
        representativePhone: activeCompany.representativePhone,
        workForce: activeCompany.workForce,
        laborUnion: activeCompany.laborUnion,
        buildingConventionOutside77: activeCompany.buildingConventionOutside77,
        agreeOptionalWorker: activeCompany.agreeOptionalWorker,
      });
    }
  }, [activeCompany]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserUpdate((prevUser) => ({
      ...prevUser,
      [name]:
        type === "radio"
          ? value === "true"
          : type === "checkbox"
          ? checked
          : value,
    }));
  };

  const handleWorkForceChange = (e) => {
    setUserUpdate((prevUser) => ({
      ...prevUser,
      workForce: e,
    }));
  };

  const checkCorrectMembershipCategory = () => {
    // let nextCategory = user.membershipCategory;

    if (
      userUpdate?.laborUnion === 2 ||
      (userUpdate?.laborUnion !== 2 &&
        userUpdate?.buildingConventionOutside77 &&
        userUpdate?.workForce === "upTo10")
    ) {
      return 6;
    } else if (userUpdate?.buildingConventionOutside77) {
      return 4;
    } else {
      return 5;
    }
  };

  const checkAndSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    const requestBody = {
      membershipCode: userUpdate.membershipCode,
      laborUnion: userUpdate.laborUnion,
      workForce: userUpdate.workForce,
      buildingConventionOutside77: userUpdate.buildingConventionOutside77,
      agreeOptionalWorker:
        userUpdate.workForce === "moreThan10"
          ? userUpdate.agreeOptionalWorker
          : false,
      address1: userUpdate.address1,
      address2: userUpdate.address2,
      address3: userUpdate.address3,
      address4: userUpdate.address4,
      zipcode: userUpdate.zipcode,
      city: userUpdate.city,
      representativeFirstname: userUpdate.representativeFirstname,
      representativeFunction: userUpdate.representativeFunction,
      representativeLastname: userUpdate.representativeLastname,
      representativePhone: userUpdate.representativePhone,
    };

    axios
      .post(`${BACKEND_URL}/api/company/update`, requestBody, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        setIsLoading(false);
        navigate("/infoIdentifier", {
          state: { message: userUpdate.membershipCode },
        });
      })
      .catch((error) => {
        setIsLoading(false);
        openNotification("Erreur", error.response.data.data, "error");
        console.error("error", error);
      });
  };

  const selectWorkforce = (
    <Select
      name="workForce"
      defaultValue={
        userUpdate?.workForce ? userUpdate?.workForce : activeCompany.workForce
      }
      onChange={handleWorkForceChange}
      options={[
        { value: "upTo10", label: "Jusqu'à 10" },
        { value: "moreThan10", label: "Plus de 10" },
      ]}
    />
  );

  const selectLaborUnion = (
    <Select
      name="laborUnion"
      entity="laborUnion"
      defaultValue={
        userUpdate?.laborUnion
          ? userUpdate?.laborUnion
          : activeCompany.laborUnion
      }
      onChange={(event) =>
        handleChange({
          target: {
            name: "laborUnion",
            value: typeof event === "string" ? Number(event) : event,
          },
        })
      }
      options={[
        { value: 2, label: "CAPEB" },
        { value: 3, label: "FFB" },
        { value: 1, label: "Aucun" },
      ]}
    />
  );

  userRef.current = user; // Mis à jour user avant handleSuccessDetachGroup()

  const handleSuccessDetachGroup = () => {
    const currentUser = userRef.current;
    if (currentUser && currentUser?.companies?.length >= 1) {
      navigate("/infoIdentifier");
      changeActiveCompany({});
    } else if (currentUser && currentUser?.companies?.length === 0) {
      navigate("/empty");
    }
  };

  return (
    <>
      <Spin spinning={isLoading} fullscreen />
      <div className="info-detail update-page">
        <form onSubmit={checkAndSubmit} data-testid="infoDetailUpdate">
          <div style={{ display: "flex" }}>
            <h2>Modification </h2>
            {!isAdmin && (
              <DetacheGroup
                page="infoDetail"
                data={activeCompany}
                afterClose={handleSuccessDetachGroup}
              />
            )}
          </div>
          <div className="info-identifiant">
            <InfoRow
              left="Identifiant APAS :"
              right={userUpdate?.membershipCode}
              width="150"
            />
            {user.isAdmin ? (
              <InfoRow
                left="Raison sociale :"
                right={
                  <input
                    type="text"
                    name="companyName"
                    value={userUpdate?.companyName}
                    onChange={handleChange}
                    disabled={!isAdmin}
                    style={{ backgroundColor: !isAdmin && "rgb(214 214 214)" }}
                    readOnly
                  />
                }
                width="150"
              />
            ) : (
              <InfoRow
                left="Raison sociale :"
                right={userUpdate?.companyName}
              />
            )}
          </div>

          <div className="info-adherent-effectif">
            <div className="adherent-effectif-container">
              <div className="adherent">
                <InfoRow
                  left="Adhérent :"
                  right={selectLaborUnion}
                  nameInput="laborUnion"
                  idToTest="testUpdatelaborUnion"
                />
              </div>

              <div className="effectif" style={{ alignItems: "center" }}>
                <InfoRow
                  left="Effectifs :"
                  right={selectWorkforce}
                  nameInput="workForce"
                  idToTest="testWorkForce"
                />
              </div>
            </div>
            <div className="checkbox-container">
              <div className="box-label-input">
                <Checkbox
                  onChange={handleChange}
                  checked={userUpdate?.buildingConventionOutside77}
                  name="buildingConventionOutside77"
                >
                  Conventions du Bâtiment hors 77 (case à décocher si l'Adhérent
                  est Volontaire ou situé dans le 77)
                </Checkbox>
              </div>

              {/* Condition: userUpdate.workForce === "moreThan10" && userUpdate.laborUnion !== 2 */}
              {conditionCheckbox && (
                <div className="box-label-input">
                  <Checkbox
                    onChange={handleChange}
                    name="agreeOptionalWorker"
                    checked={userUpdate?.agreeOptionalWorker}
                  >
                    J'ai pris connaissance que la souscription aux Prestations
                    pour les Ouvriers est optionnelle si les effectifs sont
                    supérieurs à 10.
                  </Checkbox>
                </div>
              )}
            </div>
          </div>

          <div className="info-address">
            <InfoRow
              left="Adresse :"
              right={
                <input
                  name="address2"
                  type="text"
                  id="address2"
                  defaultValue={userUpdate?.address2}
                  onChange={handleChange}
                  className="address"
                />
              }
              nameInput="address2"
            />
            <div className="zipcode">
              <InfoRow
                left="Code Postal :"
                right={
                  <input
                    type="text"
                    name="zipcode"
                    id="zipcode"
                    value={userUpdate?.zipcode}
                    onChange={handleChange}
                  />
                }
                nameInput="zipcode"
              />
              <InfoRow
                left="Ville :"
                right={
                  <input
                    type="text"
                    name="city"
                    id="city"
                    value={userUpdate?.city}
                    onChange={handleChange}
                  />
                }
                nameInput="city"
              />
            </div>

            <InfoRow
              left="SIREN :"
              right={
                <input
                  type="text"
                  name="siren"
                  id="siren"
                  value={userUpdate?.siren}
                  onChange={handleChange}
                />
              }
              nameInput="siren"
            />
          </div>

          <div className="info-referent">
            <h3>Représentant légal ou Référent</h3>
            <div className="name-container">
              <InfoRow
                left="Nom :"
                right={
                  <input
                    type="text"
                    name="representativeLastname"
                    id="representativeLastname"
                    value={userUpdate?.representativeLastname}
                    onChange={handleChange}
                  />
                }
                nameInput="representativeLastname"
              />

              <InfoRow
                left="Prénom :"
                right={
                  <input
                    type="text"
                    name="representativeFirstname"
                    id="representativeFirstname"
                    value={userUpdate?.representativeFirstname}
                    onChange={handleChange}
                  />
                }
                nameInput="representativeFirstname"
              />
            </div>
            <div className="function-tel-container">
              <InfoRow
                left="Fonction :"
                right={
                  <input
                    type="text"
                    name="representativeFunction"
                    id="representativeFunction"
                    value={userUpdate?.representativeFunction}
                    onChange={handleChange}
                  />
                }
                nameInput="representativeFunction"
              />

              <InfoRow
                left="Tél :"
                right={
                  <input
                    type="text"
                    name="representativePhone"
                    id="representativePhone"
                    value={userUpdate?.representativePhone}
                    onChange={handleChange}
                  />
                }
                nameInput="representativePhone"
              />
            </div>
          </div>

          <div style={{ display: "flex", gap: "30px", marginTop: "20px" }}>
            <ButtonLink to="/infoIdentifier" text="Retour">
              Retour
            </ButtonLink>
            <Button
              type="submit"
              children={isLoading ? <SpinLoading /> : "Valider"}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default InfoDetailUpdate;
