import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { BACKEND_URL } from "../../../config/config";
import Qs from "qs";
import SearchAutocomplete from "../../misc/searchAutocomplete/SearchAutocomplete";
import { EditOutlined, ClearOutlined, CheckOutlined } from "@ant-design/icons";
import { NotificationContext } from "../../context/NotificationWrapper";
import { Button } from "../../misc/button/Button";
import TableDeclarationsUserList from "./TableDeclarationsUserList";
import DetacheGroup from "../multiAccount/DetacheGroup";
import EditUserModal from "../userDetail-CRUD/EditUserModal";
import { useLocation } from "react-router-dom";
import ForceValidateEmailModal from "./ForceValidateEmailModal";

export default function UserList() {
  const [data, setData] = useState(null);
  const [totalItems, setTotalItems] = useState(1);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [forceValidateEmailModalVisible, setForceValidateEmailModalVisible] = useState(false);
  const [editedRecord, setEditedRecord] = useState(null);
  const [searchValue, setSearchValue] = useState(""); // string
  const [category, setCategory] = useState([]);
  const [laborUnion, setLaborUnion] = useState([]);
  const [laborUnionOption, setLaborUnionOption] = useState([]);
  const [workForce, setWorkForce] = useState();
  const [buildingConventionOutside77, setBuildingConventionOutside77] =
    useState([]);
  const [userIsAdmin, setUserIsAdmin] = useState(["false"]);
  const [isVerified, setIsVerified] = useState(["true"]);
  const [isLoading, setIsLoading] = useState(false);
  const { openNotification } = useContext(NotificationContext);
  const { state } = useLocation(); // data passed by the tiles in admin dashboard

  const [filters, setFilters] = useState({
    page: 1,
    user: null,
    membershipCategory: null,
    laborUnion: null,
    buildingConventionOutside77: null,
    workForce: null,
    isAdmin: false,
    isVerified: true,
    claimDate: state?.claimDate || null,
  });

  useEffect(() => {
    fetchBackgroundData();
    if (state) {
      setFilters((precFilters) => ({
        ...precFilters,
        claimDate: state?.claimDate || null,
        isVerified: state?.isVerified || null,
      }));
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [filters]);

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(`${BACKEND_URL}/api/user/list?`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params: getQueryParams(),
        paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: "comma", encode: false });
        },
      })
      .then((response) => {
        setIsLoading(false);
        setTotalItems(response?.data?.totalItems);
        formatData(response.data[0]);
      })
      .catch((error) => {
        setIsLoading(false);
        openNotification("Erreur", error?.response?.data?.data, "error");
        console.error("error", error);
      });
  };

  const fetchBackgroundData = () => {
    axios
      .get(`${BACKEND_URL}/api/laborUnion/list`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        formatAndSetLaborUnion(response.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const formatAndSetLaborUnion = (list) => {
    const formattedOption = list.map((el) => ({
      text: el.label,
      value: el.id,
    }));
    setLaborUnionOption(formattedOption);
  };

  // GET filters
  const getQueryParams = () => {
    Object.keys(filters).forEach((key) => {
      if (filters[key] === null) {
        delete filters[key];
      }
      // no need to send these if all options are selected
      const maxLength2 = [
        "buildingConventionOutside77",
        "workForce",
        "isUserAdmin",
        "isVerified",
      ];
      if (
        maxLength2.includes(key) &&
        typeof filters[key] !== "undefined" &&
        filters[key]?.length >= 2
      ) {
        delete filters[key];
      }
      if (
        (key === "membershipCategory" || key === "laborUnion") &&
        typeof filters[key] !== "undefined" &&
        filters[key]?.length >= 3
      ) {
        delete filters[key];
      }
    });
    return filters;
  };

  // Render form table of user
  const formatData = (dataList) => {
    let formattedData = {};
    formattedData = dataList.map((el) => ({
      id: el.id,
      key: el.id,
      email: <div>{el.email}</div>,
      companyName: <div style={{ fontWeight: "bold" }}>{el.companyName}</div>,
      membershipCode: (
        <div style={{ fontWeight: "bold" }}>{el.membershipCode}</div>
      ),
      membershipCategory: (
        <div style={{ fontWeight: "bold" }}>{el.membershipCategory}</div>
      ),
      laborUnion: <div>{el.laborUnionLabel}</div>,
      workForce: (
        <div>{el.workForce === "upTo10" ? "Jusqu'à 10" : "Plus de 10"}</div>
      ),
      representativeName: (
        <div>{`${
          el?.representativeFirstname ? el?.representativeFirstname : ""
        } ${
          el?.representativeLastname ? el?.representativeLastname : ""
        }`}</div>
      ),
      buildingConventionOutside77: (
        <div>{el.buildingConventionOutside77 ? "oui" : "non"}</div>
      ),
      isAdmin: <div>{el.isAdmin ? "oui" : "non"}</div>,
      isVerified: <div>{el.isVerified ? "oui" : "non"}</div>,
      edit: el.isVerified ? (
        <div style={{ display: "flex", gap: "15px" }}>
          <Button
            onClick={() => handleEdit(el)}
            classNameElement="btn-edit-download"
            children={<EditOutlined />}
            title="Modifier"
          />
          <DetacheGroup
            page="userList"
            data={el}
            afterClose={handleResetFilters}
          />
        </div>
      ) : (
        <div>
          {el.nextEmail && (
            <Button 
              onClick={() => handleForceValidateEmail(el)}
              classNameElement="btn-edit-download"
              title="Forcer validation email"
            >
              <CheckOutlined />
            </Button>
          )}
        </div>
      ),
    }));
    setData(formattedData);
  };

  // open modal and set data inside
  const handleEdit = (record) => {
    setEditedRecord(record);
    setEditModalVisible(true);
  };

  const handleForceValidateEmail = (record) => {
    setEditedRecord(record);
    setForceValidateEmailModalVisible(true);
  }

  const handleForceValidateSave = () => {
    const payload = {membershipCode: editedRecord?.membershipCode}
    axios
      .post(`${BACKEND_URL}/api/admin/user/force-valid-user`, payload, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        setForceValidateEmailModalVisible(false);
        fetchData()
      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  const handleForceValidateModalCancel = (editedData) => {
    setForceValidateEmailModalVisible(false);
  }



  const handleResetFilters = () => {
    setFilters({
      page: 1,
      user: null,
      membershipCategory: null,
      laborUnion: null,
      buildingConventionOutside77: null,
      workForce: null,
      isAdmin: false,
      isVerified: true,
    });
    setCategory([]);
    fetchBackgroundData(); // to refresh laborUnions
    setWorkForce([]);
    setBuildingConventionOutside77([]);
    setUserIsAdmin(["false"]);
    setIsVerified(["true"]);
    setSearchValue("");
  };

  // change the filters variable
  const handleTableChange = (e, value) => {
    setCategory(value.membershipCategory);
    setLaborUnion(value.laborUnion);
    setWorkForce(value.workForce);
    setBuildingConventionOutside77(value.buildingConventionOutside77);
    setUserIsAdmin(value.isAdmin);
    setIsVerified(value.isVerified);
    setFilters({
      page: e.current,
      membershipCategory: value.membershipCategory,
      laborUnion: value.laborUnion,
      buildingConventionOutside77: value.buildingConventionOutside77,
      workForce: value.workForce,
      isAdmin: value.isAdmin,
      isVerified: value.isVerified,
    });
  };

  const handleEditModalCancel = () => {
    setEditModalVisible(false);
  };

  // save edit modal
  const handleEditSave = (editedData) => {
    axios
      // .post(`${BACKEND_URL}/api/company/update`, editedData, {
        .post(`${BACKEND_URL}/api/admin/user/edit`, editedData, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(() => {
        setEditModalVisible(false);
        handleResetFilters();
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const handleUserSearch = (e) => {
    setFilters((prevFilters) => ({
      page: filters.page,
      company: e?.type === "company" ? e.value : null,
      user: e?.type === "user" ? e.value : null,
      membershipCategory: prevFilters.membershipCategory,
      laborUnion: prevFilters.laborUnion,
      buildingConventionOutside77: prevFilters.buildingConventionOutside77,
      workForce: prevFilters.workForce,
      isAdmin: prevFilters.isAdmin,
      isVerified: prevFilters.isVerified,
    }));
  };

  const handleUserChange = (e) => {
    setSearchValue(e);
  };

  const handleExportCSV = () => {
    axios
      .get(`${BACKEND_URL}/api/csv/exportuser?`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        params: getQueryParams(),
        paramsSerializer: function (params) {
          return Qs.stringify(params, { arrayFormat: "comma", encode: false });
        },
        responseType: "blob",
      })
      .then((response) => {
        const href = URL.createObjectURL(response?.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        const date = new Date();
        link.setAttribute(
          "download",
          `users_${date.getDate()}_${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}_${date.getUTCFullYear()}.csv`
        );
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  return (
    <div className="block-right">
      <div className="info-table">
        <div className="input-container">
          <div className="findUser-container">
            <SearchAutocomplete
              entity="user"
              name="user"
              defaultValue={null}
              style={{ width: "200px", marginRight: "20px" }}
              onValidate={handleUserSearch}
              handleChange={handleUserChange}
              value={searchValue}
            />

            <Button
              onClick={handleResetFilters}
              children={<ClearOutlined />}
              title="Reset"
            />
          </div>

          <div className="findMond-reset-container">
            <div></div>
          </div>
          <Button onClick={handleExportCSV} children={"Export CSV"} />
        </div>
        <div>
          <TableDeclarationsUserList
            category={category}
            laborUnionOption={laborUnionOption}
            laborUnion={laborUnion}
            buildingConventionOutside77={buildingConventionOutside77}
            workForce={workForce}
            userIsAdmin={userIsAdmin}
            isVerified={isVerified}
            data={data}
            filters={filters}
            totalItems={totalItems}
            handleTableChange={handleTableChange}
            isLoading={isLoading}
          />
        </div>
      </div>
      <EditUserModal
        open={editModalVisible}
        onCancel={handleEditModalCancel}
        onEdit={handleEditSave}
        record={editedRecord}
        laborUnionOption={laborUnionOption}
      />
      <ForceValidateEmailModal
        open={forceValidateEmailModalVisible}
        onCancel={handleForceValidateModalCancel}
        onValidate={handleForceValidateSave}
        record={editedRecord}
      />
    </div>
  );
}
